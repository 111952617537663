export default [
  {
    title: 'Activities & Events',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: "client-home",
    action: "read",
    resource: "ClientHomePage"
  },
  {
    title: 'Reservations',
    icon: 'BookIcon',
    // tag: '2',
    tagVariant: 'light-warning',
    route: "client-reservations",
    action: "read",
    resource: "ClientLoansPage"
  },
  {
    title: 'Blogs',
    icon: 'CloudSnowIcon',
    tagVariant: 'light-warning',
    route: "client-blogs",
    action: "read",
    resource: "ClientShopPage"
  },
]
