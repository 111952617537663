export default [
  {
    title: 'HOME',
    action: "read",
    route: "app-root",
    resource: "AppRoot"
  },
  {
    title: 'HISTORY',
    action: "read",
    route: "about-us-history",
    resource: "PublicPage"
  },
  {
    title: 'LEADERSHIP',
    action: "read",
    route: "about-us-leadership",
    resource: "PublicPage"
  },
  {
    title: 'CAMPUSES',
    action: "read",
    route: "about-us-campus",
    resource: "PublicPage"
  },
  {
    title: 'SECTORS',
    action: "read",
    route: "about-us-sectors",
    resource: "PublicPage"
  },
  {
    title: 'ACTIVITIES / EVENTS',
    action: "read",
    route: "events",
    resource: "PublicPage"
  },
  {
    title: 'BLOG',
    action: "read",
    route: "blog",
    resource: "PublicPage"
  },
  // {
  //   title: 'Cinema',
  //   action: "read",
  //   route: "cinema",
  //   resource: "PublicPage"
  // },
  // {
  //   title: 'Entrepreneurs Club',
  //   action: "read",
  //   route: "entrepreneurs-club",
  //   resource: "PublicPage"
  // },
  // {
  //   title: 'Investors Club',
  //   action: "read",
  //   route: "investors-club",
  //   resource: "PublicPage"
  // },
]
