<template>
<div class="">

  <div class="navbar-container d-flex align-items-center w-100 justify-content-between">
    <!--left hand side-->
    <div class="flex-fill mr-5">
      <div class="d-lg-none">
        <b-link class="nav-link d-xl-none" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </div>
    </div>

    <!-- right hand side-->
    <div class="pl-md-5 ml-md-5">
      <b-navbar-nav class="nav align-items-center ">
        <!-- <locale /> -->
        <dark-Toggler class="d-lg-block" />
        <!-- <cart-dropdown /> -->
        <!-- <notification-dropdown /> -->
        <user-dropdown />
      </b-navbar-nav>
    </div>
  </div>

  <div class="navbar-container">
    <div>
      <div class="nav-item d-sm-block">
        <div class="d-flex align-items-center">
          <h3 style="color: #144F6A;">
            {{ getValueFromSource($route, 'meta.pageTitle', '') }}</h3>
        </div>
      </div>
    </div>

    <app-breadcrumb v-if="(y === 0) || (y > 0 && y <= 27)" />
  </div>

</div>
</template>

<script>
import {
  BLink,
  BAvatar,
  BNavbarNav,
  BProgress,
  BProgressBar,
  BAlert,
  BButton,
} from 'bootstrap-vue'
import {
  useWindowScroll
} from '@vueuse/core'

import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'

import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import Bookmarks from './components/Bookmarks.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BAlert,
    BAvatar,
    BButton,
    BProgress,
    BProgressBar,
    AppBreadcrumb,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
  },
  setup() {
    const {
      y
    } = useWindowScroll()
    return {
      y
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
</style>
